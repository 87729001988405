<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <chem-components
          :is="tab.component"
          :param="popupParam"
          @insertGovScheduleInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'gov-schedule-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'govScheduleInfo',
      tabItems: [
        { name: 'govScheduleInfo', icon: 'info', label: '예방접종 기본정보', component: () => import(`${'./testVaccinationInfo.vue'}`) },
      ],
      addTabItems: [
        // { name: 'govScheduleHistory', icon: 'construction', label: '예방접종 실시이력', component: () => import(`${'./testVaccinationHistory.vue'}`) },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.govScheduleMstId != '') {
        // this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(_govScheduleMstId) {
      this.popupParam.govScheduleMstId = _govScheduleMstId;
      // this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>